import { Box, CircularProgress, Typography } from "@mui/material";
import { ImageContainer } from "../../../shared/components/ImageContainer";
import { CopyIcon, EyeIcon } from "../../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../../utils/extensions";
import { toastError, toastSuccess } from "../../../utils/ToastMessage";
import Cookies from "js-cookie";
import { colors } from "../../../utils/colors";
import { useGlobalContext } from "../../../App";
import { getComponentSourceCodeByComponentId } from "../../../services/component";
import { CLOUDFRONT_DOMAIN } from "../../../utils/constants";
import { useState } from "react";

type Props = {
  data: any;
  userSubscriptions?: any;
  isSubscribed?: any;
  setScrollPosition?: any;
};

const ComponentCard = (props: Props) => {
  const navigate = useNavigate();
  const { setActiveLoginDialog } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const { data, isSubscribed } = props;

  const handleCopyFigmaCode = async () => {
    setLoading(true);
    if (!Cookies.get("jstoken")) {
      setActiveLoginDialog(true);
      return;
    }

    if (!isSubscribed) {
      navigate("/pricing");
      toastError("Please Subscribe to copy the Component.");
      return;
    }
    let componentSourceCode: any = "";

    try {
      componentSourceCode = await getComponentSourceCodeByComponentId(
        data?._id
      );
      const htmlContent = componentSourceCode?.data?.figmaCode;
      // Create a Blob with the HTML content and specify the MIME type as 'text/html'
      const blob = new Blob([htmlContent], { type: "text/html" });
      const clipboardItem = new ClipboardItem({ "text/html": blob });
      // Copy the Blob to the clipboard
      navigator.clipboard
        .write([clipboardItem])
        .then(() => {
          toastSuccess("Successfull", "Component is copied");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } catch (error) {
      toastError("Failed", "Something went wrong.");
    }
    setLoading(false);
  };
  const handleShortBlurb = (blurb: string) => {
    return blurb.length > 52 ? `${blurb.slice(0, 49)}...` : blurb;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // aspectRatio: "360/327",
        aspectRatio: "4/3",
        borderRadius: "12px",
        bgcolor: colors.primaryGrey,
        width: "100%",
        minWidth: "100%",
        maxWidth: { xs: "100%", sm: "320px", md: "360px", lg: "100%" },
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          borderRadius: "12px",
          // overflow: "hidden", // Ensures content inside respects border radius
          "&::before": {
            content: '""',
            display: "block",
            paddingTop: "75%", // 4:3 aspect ratio (3/4 = 0.75 * 100)
          },
          backgroundImage: data?.documents
            ? `url(${data?.documents[0]?.url})`
            : "",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          "&:hover .overlay": {
            opacity: 1, // Make the overlay visible on hover
          },
          aspectRatio: "4/3",
        }}
      >
        <Box
          className="overlay"
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            opacity: 0, // Start with the overlay hidden
            transition: "opacity 0.3s ease", // Smooth transition effect
            // borderRadius: "12px", 
            borderRadius: "9px 9px 9.5px 9.5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            padding: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "17px",
            }}
          >
            {
              <Box
                onClick={(e: any) => {
                  e.preventDefault();
                  handleCopyFigmaCode();
                }}
                sx={{
                  width: "46px",
                  height: "46px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "white",
                  cursor: "pointer",
                }}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "black",
                    }}
                    size={24}
                  />
                ) : (
                  <ImageContainer
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    title="copy"
                  >
                    {CopyIcon}
                  </ImageContainer>
                )}
              </Box>
            }

            <Box
              onClick={() => {
                // props?.setScrollPosition(window.scrollY);
                navigate(`/component/${data?.blurbUrl}`, {
                  state: { id: data?.id, component: data },
                });
                // Smooth scroll to the top of the page
                window.scrollTo({
                  top: 0,
                  behavior: "auto",
                });
              }}
              sx={{
                width: "46px",
                height: "46px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
                cursor: "pointer",
              }}
            >
              <ImageContainer
                style={{
                  width: "25px",
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                title="eye"
              >
                {EyeIcon}
              </ImageContainer>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          alignItems: "flex-start",
          gap: "12px",
        }}
      >
        <Typography color={"white"} fontWeight={600} variant="subtitle1">
          {data?.title}
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            overflow: "hidden",
          }}
          variant="body1"
          color={"rgba(255, 255, 255, 0.5)"}
        >
          {handleShortBlurb(data?.blurb)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ComponentCard;
