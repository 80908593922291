import { Button, Box, Typography } from "@mui/material";
import NotFoundIcon from "../../assets/icons/404/NotFoundIcon";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  
  const navigate = useNavigate();
  return (
    <>

    {/* Main Section Details */}

    <Box sx={{ 
      maxWidth:"1440px",
        margin:"auto",
        display: "flex", 
        flexDirection: {
          xs: "column", // mobile
          sm: "column-reverse", // tablet
          md: "row",    // desktop
        },
        justifyContent: {
          xs: "center",
          sm: "center", 
          md: "flex-start", 
        },
        gap: {
          xs: "16px",
          sm: "24px", 
          md: "32px", 
        },
        padding: {
          xs: "16px",
          // sm: "24px",
          md: "32px", 
        },
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "80px 93px 206px 160px",
          width: "591px",
          height: "332px",
          gap: "32px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Typography
            variant="body2"
            sx={{
              width: "110px",
              height: "48px",
              borderRadius: "79px",
              color: "#8D8D8D",
              backgroundColor: "#1B1B1B",
              border: "1px solid #3D3D3D",
              padding: "12px 22px 12px 23px",
              fontSize: "15.4px",
              lineHeight: "24px",
              fontWeight: 400,
              marginBottom: "24px",
            }}
          >
            Error 404
          </Typography>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "24px" }}>
            <Typography
              variant="h3"
              sx={{
                // width: "591px",
                // height:"128px",
                minWidth:"340px", 
                fontSize: "58px",
                lineHeight: "64px",
                fontWeight: 600,
                background: "linear-gradient(to right, #FFFFFF, #7A7A7A)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                "& span": {
                  WebkitBackgroundClip: "initial", 
                  WebkitTextFillColor: "initial", 
                  color: "inherit", 
                },
              }}
            >
              Oops! You've Found a <br /> Design Black Hole!<span>🚀</span>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                maxWidth:"568px",
                minWidth:"320px", 
                // height:"22px", 
                fontSize: "18px",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              You're too awesome to get stuck here. Let's get back to creating!😎✨
            </Typography>
          </Box>
        </Box>
        <Box>
          <Button
            sx={{
              // width: "250px",
              height: "54px",
              borderRadius: "16px",
              backgroundColor: "#CCFF00",
              border: "1px solid #718B08",
              padding: "16px 40px 16px 38px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "22px",
              color: "#000000",
              "&:hover": {
                backgroundColor: "#BBDD00",
              },
            }}
            onClick={() => navigate("/")}
          >
            Back to Homepage
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          margin: {
            xs: "40px 32px 40px 0", 
            sm: "60px 80px 60px 0", 
            md: "80px 116px 58px 0", 
          },
          display: "flex", 
          justifyContent: {
            xs: "center",
            sm: "center", 
            md: "center", 
          },
        }}
      >
        <NotFoundIcon />
      </Box>
      
    </Box>
       
    </>
  );
};

export default NotFound;
