import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import FilterOptionsSidebar from "./FilterOptionsSidebar";
import ComponentCard from "../home/components/ComponentCard";
import { ButtonPrimary } from "../../shared/components/button/Buttons";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { EmptySearchIcon, WikiLogo } from "../../assets/icons/icons";
import { useGlobalContext } from "../../App";
import { getAllComponents } from "../../services/component";
import DefaultLoading from "../../shared/Loading/DefaultLoading";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../main/Footer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { CrossIconSvg } from "../../assets/icons/CrossIconSvg";
import Search from "../../shared/components/Search";
import { getUserById } from "../../services/user";

type Props = {};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    maxWidth: `100%`,
    opacity: 1,
    background: "transparent", // Ensure full opacity black
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: "transparent", // Ensure full opacity black
  boxShadow: "none !important", // Disable box shadow to avoid lightening effect
  height: "120px",
  "@media (max-width: 600px)": {
    height: "96px",
  },
  opacity: 1,
  marginRight: "0px",
}));

const SearchScreen = (props: Props) => {
  const {
    searchProductBy,
    setActiveNavigation,
    setSearchProductBy,
    userDetails,
  } = useGlobalContext();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const breakpoint_down_769 = useMediaQuery(theme.breakpoints.down(700));

  //This is debouncing code for search
  const DEBOUNCE_DELAY = 700; // Adjust the delay as needed

  // TabPanel handling states and functions
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Refrence for search input
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [scrollPosition, setScrollPosition] = useState<any>(0);

  // ------Sidebar handling states and functions------
  const [open, setOpen] = React.useState(true);

  const [activeFilters, setActiveFilters] = useState<any>({
    categories: [],
    tags: [],
  });
  const [filteredList, setFilteredList] = useState<any>([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);

  // current page and rows per page handling states and functions
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const [hasMore, setHasMore] = useState(true);

  const getComponents = async (filters?: any, resetPage = false) => {
    try {
      await getAllComponents(filters)
        .then((res: any) => {
          if (resetPage) {
            setFilteredList(res);
          } else {
            setFilteredList((prevList: any) => ({
              paginatedComponents: [
                ...(prevList?.paginatedComponents || []),
                ...(res?.paginatedComponents || []),
              ],
              totalComponents: res?.totalComponents,
            }));
          }
          setHasMore(res.totalComponents > rowsPerPage * page);
          setLoadMoreLoading(false);
          setLoading(false);
        })
        .catch((err: any) => {
          console.log("err", err);
          setLoadMoreLoading(false);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoadMoreLoading(false);
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    setPage((prevPage) => prevPage + 1);
  };

  const handleChangeActiveFilters = (type: string, value: string) => {
    setActiveFilters((prev: any) => {
      switch (type) {
        case "categories": {
          const isTagSelected = prev?.categories?.includes(value);
          return {
            ...prev,
            categories: isTagSelected
              ? prev?.categories?.filter(
                  (category: string) => category !== value
                )
              : [...prev?.categories, value],
          };
        }
        case "tags": {
          const isTagSelected = prev?.tags?.includes(value);
          return {
            ...prev,
            tags: isTagSelected
              ? prev?.tags?.filter((t: string) => t !== value)
              : [...prev?.tags, value],
          };
        }
        default:
          return prev;
      }
    });
  };

  const handleClearAllFilters = (type: string) => {
    setActiveFilters((prev: any) => {
      switch (type) {
        case "categories": {
          return {
            ...prev,
            categories: [],
          };
        }
        case "tags": {
          return {
            ...prev,
            tags: [],
          };
        }
        default:
          return prev;
      }
    });
  };

  const getUserSubscriptionsDetails = async (id: string) => {
    const response = await getUserById(id);
    const subscriptions = response?.data?.subscriptions;

    if (subscriptions?.length > 0) {
      setUserSubscriptions(subscriptions);
      for (let subscription of subscriptions) {
        if (subscription.isPaid === true && subscription.status === "ACTIVE") {
          setIsSubscribed(true);
        }
      }
    }
  };

  useEffect(() => {
    if (hasMore && !loading) {
      getComponents({
        searchBy: searchProductBy,
        page: page,
        pageSize: rowsPerPage,
        tags: [
          ...(activeFilters?.categories || []),
          ...(activeFilters?.tags || []),
        ],
      });
    }
  }, [page]);

  // Infinite scroll handler using IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMore && !loading) {
          handleLoadMore(); // Load more components when reaching the end
        }
      },
      { threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loading, hasMore]);

  useEffect(() => {
    const handleSearch = () => {
      setLoading(true);
      setPage(1);
      const incomingTag = location?.state?.tag || "";
      const activeTags = [
        ...(activeFilters?.categories || []),
        ...(activeFilters?.tags || []),
      ];
      getComponents(
        {
          searchBy: searchProductBy,
          page: 1,
          pageSize: rowsPerPage,
          tags:
            activeTags?.length > 0
              ? activeTags
              : incomingTag
              ? [incomingTag]
              : [],
        },
        true
      );
    };

    const handler = setTimeout(() => {
      handleSearch();
    }, DEBOUNCE_DELAY);

    const handleKeyDown = (event: any) => {
      if (event.code === "Space") {
        clearTimeout(handler);
        handleSearch();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      clearTimeout(handler);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchProductBy, activeFilters]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getUserSubscriptionsDetails(userDetails._id);
    }
  }, [userDetails]);

  useEffect(() => {
    // Restore scroll position
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);


  // for retain scroll when user back from perticular component

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;

    const saveScrollPosition = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        sessionStorage.setItem("scrollPosition", window.scrollY.toString());
      }, 200); // Debounce 
    };

    const restoreScrollPosition = () => {
      const savedPosition = sessionStorage.getItem("scrollPosition");
      if (savedPosition !== null) {
        setTimeout(() => {
          window.scrollTo(0, Number(savedPosition));
        }, 500);
      }
    };

    window.addEventListener("scroll", saveScrollPosition);
    restoreScrollPosition();

    return () => {
      clearTimeout(debounceTimer);
      window.removeEventListener("scroll", saveScrollPosition);
    };
  }, []); 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flex: 1,
        minHeight: "100vh",
      }}
    >
      {/* -----Appbar section----- */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <AppBar
          position="relative"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            backgroundColor: "#0C0C0C !important",
            marginRight: !open ? "0px" : "0px",
            borderBottom:
              window.location.pathname !== "/"
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "none",
            flex: "1",
            opacity: 1,
            width: {
              xs: "100%",
            },
            maxWidth: {
              xs: "100%",
            },
            transition: theme.transitions.create(["width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.down("lg")]: {
              marginLeft: 0,
              ...(open && {
                transition: theme.transitions.create(["margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }),
            },
          }}
          open={open}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
              width: "100%",
              gap: { xs: "12px", sm: "35px" },
              position: "relative",
              paddingLeft: "24px",
            }}
          >
            <Box
              onClick={() => {
                setActiveNavigation((prevNav: any) => {
                  window.localStorage.setItem("ACTIVE_NAVIGATION", "/");
                  return "/";
                });
                navigate("/");
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                cursor: "pointer",
              }}
            >
              <ImageContainer
                style={{ width: "54px", height: "54px" }}
                height="54px"
                title="Main Logo"
              >
                {WikiLogo}
              </ImageContainer>
            </Box>

            <Search
              id="appbarSearch"
              searchFormStyles={{
                boxShadow: "none !important",
                maxWidth: "90%",
                color: "white",
                fontSize: { xs: "14px", sm: "24px" },
              }}
              style={{
                boxShadow: "none !important",
                fontSize: "28px",
                "@media (max-width: 600px)": {
                  fontSize: "14px !important",
                },
                maxHeight: "44px",
                color: "white",
                fontWeight: "400",
                paddingLeft: "0px",
                width: "100%",
                outline: "none",
                border: "none",
                borderRadius: "0px",
                backgroundColor: "#0C0C0C",
                lineHeight:"22px",
              }}
              placeholderStyle={{
                fontSize: "24px",
                paddingLeft: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px !important",
                },
              }}
              hideIcon={true}
              value={searchProductBy}
              onChange={(e) => {
                setSearchProductBy(e.target.value);
              }}
              placeholder="Search components, elements..."
              inputRef={searchInputRef}
            />

            <Box
              onClick={() => {
                setSearchProductBy("");
                navigate(-1);
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: "24px", sm: "44px" },
                height: { xs: "24px", sm: "44px" },
                borderRadius: "50%",
                boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                position: "absolute",
                right: 24,
                cursor: "pointer",
              }}
            >
              {theme.breakpoints.down("sm") ? (
                <CrossIconSvg width="13px" height="13px" color="white" />
              ) : (
                <CrossIconSvg width="24px" height="24px" color="white" />
              )}
            </Box>
          </Box>
        </AppBar>
      </Box>
      {/* -----End Appbar section----- */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          flex: 1,
          minHeight: `calc(100vh - 120px)`,
        }}
      >
        {!breakpoint_down_769 && (
          <FilterOptionsSidebar
            activeFilters={activeFilters}
            handleChangeActiveFilters={handleChangeActiveFilters}
            handleClearAllFilters={handleClearAllFilters}
          />
        )}

        {/* Filtered content Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: "24px",
            width: "100%",
            height: "100%",
            gap: "24px",
          }}
        >
          {/* --------Tab pannels-------- */}
          {/* <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            TabIndicatorProps={{ style: { display: "none" } }}
            TabScrollButtonProps={{
              style: {
                color: "white",
              },
            }}
            visibleScrollbar={true}
            sx={{
              borderColor: "divider",
              fontWeight: "500",
              color: "white",
              padding: "6px",
              borderRadius: "14px",
              flex: 1,
              width: "100%",
              minHeight: "fit-content",
              maxHeight: "fit-content",
              "& .MuiTabs-flexContainer": {
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "center", sm: "flex-start" },
                gap: "12px",
                padding: "0px",
              },
            }}
            aria-label="scrollable auto tabs example"
          >
            <Tab
              disableRipple
              sx={{
                textTransform: "none",
                fontWeight: "500",
                color: "white",
                padding: "0px !important",
                width: { xs: "100%", sm: "auto" },
              }}
              label={
                <Box
                  sx={{
                    bgcolor: value === 0 ? "#CCFF00" : "#141414",
                    boxShadow:
                      value === 0 ? "none" : "inset 0px 0px 0px 1px #3D3D3D",
                    color: value === 0 ? "black" : "white",
                    borderRadius: "100px",
                    minHeight: "46px !important",
                    maxHeight: "46px !important",
                    width: "100%",
                    maxWidth: { xs: "100%", sm: "228px" },
                    minWidth: { xs: "100%", sm: "228px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography fontWeight={600} variant="body1">
                    Component
                  </Typography>
                </Box>
              }
            />
            <Tab
              disableRipple
              sx={{
                textTransform: "none",
                fontWeight: "500",
                color: "white",
                padding: "0px !important",
                width: { xs: "100%", sm: "auto" },
              }}
              label={
                <Box
                  sx={{
                    bgcolor: value === 1 ? "#CCFF00" : "transparent",
                    boxShadow:
                      value === 1 ? "none" : "inset 0px 0px 0px 1px #3D3D3D",
                    color: value === 1 ? "black" : "white",
                    borderRadius: "100px",
                    minHeight: "46px !important",
                    maxHeight: "46px !important",
                    width: "100%",
                    maxWidth: { xs: "100%", sm: "228px" },
                    minWidth: { xs: "100%", sm: "228px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography fontWeight={600} variant="body1">
                    UI Kit
                  </Typography>
                </Box>
              }
            />
          </Tabs> */}

          <TabPanel value={value} index={0}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              {/* ------Filtered Cards section------ */}
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "42px",
                      height: "42px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DefaultLoading />
                  </Box>
                </Box>
              ) : filteredList?.paginatedComponents?.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                    width: "100%",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      width: "100%",
                      boxSizing: "border-box",
                      gridGap: "16px",
                      gridTemplateColumns: {
                        xs: "repeat(auto-fit, minmax(320px, 1fr))",
                        md: "repeat(auto-fit, minmax(360px, 1fr))",
                      }, // Adjust card size and fit into available space
                      "@media screen and (max-width: 979px)": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flexWrap: "wrap",
                      },
                    }}
                  >
                    {filteredList?.paginatedComponents?.map(
                      (item: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              maxWidth: {
                                xs: "360px",
                                sm: "485px",
                                // md: "100%",
                                lg: "100%",
                              },
                              minWidth: {
                                xs: "100%",
                                sm: "360px",
                                // md: "100%",
                                lg: "100%",
                              },
                              width: "100%",
                              justifySelf: "center",
                            }}
                          >
                            <ComponentCard
                              data={item}
                              isSubscribed={isSubscribed}
                              setScrollPosition={setScrollPosition}
                            />
                          </Box>
                        );
                      }
                    )}
                  </Box>

                  {/* Loader reference for infinite scroll */}
                  <Box
                    sx={{
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    ref={loaderRef}
                  >
                    {" "}
                    {loadMoreLoading && (
                      <Box
                        sx={{
                          width: "24px",
                          height: "24px",
                        }}
                      >
                        <DefaultLoading width="24px" height="24px" />
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                // ------------No results found UI------------
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "70vh",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "fit-content",
                    }}
                  >
                    <ImageContainer
                      imageContainerStyles={{
                        width: { xs: "100px", sm: "166px" },
                        height: { xs: "100px", sm: "166px" },
                      }}
                      title="find folder"
                    >
                      {EmptySearchIcon}
                    </ImageContainer>
                    <Typography
                      sx={{ color: "white", fontWeight: "600" }}
                      variant="h4"
                    >
                      Sorry! No results found
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "600",
                        width: "70%",
                        lineHeight: "130%",
                      }}
                      variant="body1"
                    >
                      Please tweak your flters, or hit the button below to reset
                      all.
                    </Typography>
                    <ButtonPrimary
                      onClick={() => {
                        setActiveFilters({
                          categories: [],
                          tags: [],
                        });
                      }}
                      sx={{
                        height: "48px",
                        width: "141px",
                        borderRadius: "10px",
                      }}
                      LabelStyle={{ fontSize: "18px", fontWeight: "500" }}
                      label="Reset Filters"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1}>
            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "42px",
                    height: "42px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DefaultLoading />
                </Box>
              </Box>
            ) : false ? ( //ADD filtered UI kit data map condition
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    width: "100%",
                    boxSizing: "border-box",
                    gridGap: "16px",
                    gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", // Adjust card size and fit into available space
                    "@media screen and (max-width: 979px)": {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  {filteredList?.paginatedComponents?.map(
                    (item: any, index: number) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            maxWidth: { xs: "360px", sm: "360px", lg: "360px" },
                            minWidth: { xs: "100%", sm: "320px", md: "100%" },
                          }}
                        >
                          <ComponentCard
                            data={item}
                            isSubscribed={isSubscribed}
                          />
                        </Box>
                      );
                    }
                  )}
                </Box>

                {/* Loader reference for infinite scroll */}
                <Box
                  sx={{
                    height: "24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  ref={loaderRef}
                >
                  {" "}
                  {loadMoreLoading && (
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <DefaultLoading width="24px" height="24px" />
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              // ------------No results found UI------------
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "70vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    width: "fit-content",
                  }}
                >
                  <ImageContainer title="find folder">
                    {EmptySearchIcon}
                  </ImageContainer>
                  <Typography
                    sx={{ color: "white", fontWeight: "600" }}
                    variant="h4"
                  >
                    Sorry! No results found
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      fontWeight: "600",
                      width: "70%",
                      lineHeight: "130%",
                    }}
                    variant="body1"
                  >
                    Please tweak your flters, or hit the button below to reset
                    all.
                  </Typography>
                  <ButtonPrimary
                    onClick={() => {
                      setActiveFilters({
                        categories: [],
                        tags: [],
                      });
                    }}
                    sx={{
                      height: "48px",
                      width: "141px",
                      borderRadius: "10px",
                    }}
                    LabelStyle={{ fontSize: "18px", fontWeight: "500" }}
                    label="Reset Filters"
                  />
                </Box>
              </Box>
            )}
          </TabPanel>
        </Box>
      </Box>

      {/* --------Footer Section-------- */}
      <Footer />
    </Box>
  );
};

export default SearchScreen;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      // sx={{ maxHeight: SECTION_MIN_HEIGHT }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
