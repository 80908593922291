import React, { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import {
  NavLink,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { darkTheme, lightTheme } from "./utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import Main from "./main/Main";
import Login from "./login/Login";
import SignUp from "./signup/SignUp";
import ResetPassword from "./resetPassword/ResetPassword";
import Cookies from "js-cookie";
import { getUserById, getUserByToken } from "./services/user";
import SearchScreen from "./pages/search/SearchScreen";
import { Box, CssBaseline } from "@mui/material";
import { getAllComponents } from "./services/component";
import DefaultLoading from "./shared/Loading/DefaultLoading";
import { ToastContainer, useToast } from "react-toastify";
import ReactGA from "react-ga4";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";

const TRACKING_ID = "G-CECE1E3NSB";
ReactGA.initialize(TRACKING_ID);

interface GlobalContextType {
  setActiveNavigation: any;
  activeNavigation: any;
  navigations: any;
  setNavigations: any;
  breadcrumbs: any;
  setBreadcrumbs: any;
  breadCrumbsLoading: any;
  setBreadCrumbsLoading: any;
  handleBreadCrumbsLoading: any;
  userDetails: any;
  setUserDetails: any;
  searchProductBy: string;
  setSearchProductBy: any;
  activeLoginDialog: any;
  setActiveLoginDialog: any;
  activeSignupDialog: any;
  setActiveSignupDialog: any;
  activeResetDialog: any;
  setActiveResetDialog: any;
}
export const GlobalContext = createContext<GlobalContextType | undefined>(
  undefined
);
export const useGlobalContext = (): any => useContext(GlobalContext)!;

declare global {
  interface Window {
    gtag: any;
  }
}

function App() {
  const [currentTheme] = useState<"light" | "dark">("dark");
  const theme = currentTheme === "light" ? lightTheme : darkTheme;

  const [loading, setLoading] = useState<boolean>(true);

  const [breadCrumbsLoading, setBreadCrumbsLoading] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<any>([
    <NavLink to="/">Home</NavLink>,
  ]);

  // User Login, logout and reset password dialogs visiblity handling states
  const [activeLoginDialog, setActiveLoginDialog] = useState<boolean>(false);
  const [activeSignupDialog, setActiveSignupDialog] = useState<boolean>(false);
  const [activeResetDialog, setActiveResetDialog] = useState<boolean>(false);

  // Loged in user details handling state
  const [userDetails, setUserDetails] = useState("");

  // All/Active navigation handling states
  const [navigations, setNavigations] = useState<any>([]);
  const [activeNavigation, setActiveNavigation] = useState<any>(() => {
    try {
      const storedValue = window.localStorage.getItem("ACTIVE_NAVIGATION");
      return storedValue ? JSON.parse(storedValue) : navigations;
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
      return navigations;
    }
  });

  // Component Search handling state
  const [searchProductBy, setSearchProductBy] = useState<string>("");
  const handleBreadCrumbsLoading: any = (loading: boolean) => {
    setBreadCrumbsLoading(loading);
  };

  const getComponents = async () => {
    try {
      await getAllComponents({ page: 1, pageSize: 24 })
        .then((res: any) => {
          localStorage.removeItem("componentsList");
          localStorage.setItem("componentsList", JSON.stringify(res));
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("componentsList")) {
      setLoading(false);
    }

    getComponents();
    const fetchUserDetails = async (token: string) => {
      const res: any = await getUserByToken(token);

      if (res) {
        setUserDetails(res?.data?.user);
        Cookies.set("jstoken", res?.data?.token);
        Cookies.set("user", JSON.stringify(res?.data));
        Cookies.set("userId", JSON.stringify(res?.data?._id));
      }
    };

    const userToken: any = localStorage.getItem("jstoken");
    if (userToken) {
      fetchUserDetails(userToken);
    } else {
      Cookies.remove("jstoken");
    }
  }, []);
  const currentPath = window.location.pathname;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: currentPath });
  }, [currentPath]);

  const globalContextProps = {
    setActiveNavigation,
    activeNavigation,
    navigations,
    setNavigations,
    breadCrumbsLoading,
    setBreadCrumbsLoading,
    breadcrumbs,
    setBreadcrumbs,
    handleBreadCrumbsLoading,
    userDetails,
    setUserDetails,
    searchProductBy,
    setSearchProductBy,
    activeLoginDialog,
    setActiveLoginDialog,
    activeSignupDialog,
    setActiveSignupDialog,
    activeResetDialog,
    setActiveResetDialog,
  };

  // if (loading)
  //   return (
  //     <Box
  //       sx={{
  //         width: "100%",
  //         height: "100vh",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           width: "62px",
  //           height: "62px",
  //         }}
  //       >
  //         <DefaultLoading width="62px" height="62px" />
  //       </Box>
  //     </Box>
  //   );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider value={globalContextProps}>
          <CssBaseline />
          <ToastContainer />
          <Router>
            <Routes>
              <Route path="/*" element={<Main />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/request-reset-password" element={<ResetPassword />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/search" element={<SearchScreen />} />
            </Routes>
          </Router>
        </GlobalContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
