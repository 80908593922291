import React from 'react'

const NotFoundIcon = () => {
  return (
    <svg width="363" height="396" viewBox="0 0 363 396" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M261.279 110.39C245.529 102.29 225.129 96.12 202.419 93.37C179.709 90.62 158.419 91.75 141.199 95.86C148.139 66.95 176.489 47.26 207.549 51.02C238.609 54.78 261.439 80.66 261.289 110.39H261.279Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M217.992 42.66C218.392 39.35 216.032 36.35 212.732 35.95C209.432 35.55 206.422 37.91 206.022 41.21C205.742 43.51 206.822 45.65 208.612 46.88L208.112 50.23L213.642 50.9L214.142 47.55C216.172 46.78 217.732 44.96 218.002 42.66H217.992ZM202.592 84.25C131.002 75.59 70.1517 91.56 66.7217 119.91C63.2917 148.27 118.582 178.28 190.172 186.94C261.762 195.6 322.612 179.63 326.042 151.28C329.472 122.92 274.182 92.91 202.592 84.25ZM194.672 166.68C152.642 161.6 119.832 147.17 121.362 134.5C122.902 121.81 158.202 115.65 200.232 120.73C242.232 125.81 273.712 140.48 273.512 152.9C271.982 165.56 236.672 171.75 194.672 166.67V166.68Z" fill="#CCFF00"/>
        <path d="M212.52 228.02C212.52 227.27 214.71 226.97 215.17 227.21C215.63 227.45 215.96 229.7 215.17 229.61C214.38 229.52 214.27 228.41 214.27 228.41C214.27 228.41 212.53 228.77 212.53 228.03H212.52V228.02Z" fill="white"/>
        <path d="M202.689 267.88C202.689 267.88 204.959 271.2 206.749 274.78C208.539 278.36 212.399 282.68 215.699 282.3C218.999 281.92 219.639 285.34 217.849 286.42C216.059 287.5 212.709 288.64 206.449 287.21C200.189 285.78 181.579 281.84 178.539 271.29C175.499 260.73 172.589 247.45 177.539 237.43C182.479 227.41 196.439 221.96 196.439 221.96C196.439 221.96 204.679 211.58 213.129 210.52C214.599 210.34 215.899 211.46 215.969 212.94L216.209 217.88C216.209 217.88 218.389 217.85 219.789 219.85C219.789 219.85 222.749 217.98 225.269 217.06C226.629 216.56 228.069 217.57 228.099 219.02C228.129 220.97 226.879 224.29 225.789 226.26C225.789 226.26 226.229 229.85 224.209 233.49C222.189 237.13 215.489 239.53 215.489 239.53" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M207.52 273.8C207.52 273.8 211.431 271.57 215.641 268.29C224.831 267.19 225.95 263.57 225.86 261.77C225.77 259.97 223.771 258.52 220.511 261.52C217.811 264 213.09 260.55 211.1 256.7C209.34 253.3 208.79 248.84 207.07 246.15M176.84 264.91C176.84 264.91 180 281.9 172.86 286.08C165.72 290.26 165.24 293.83 169.53 293.95C173.82 294.07 184.16 289.2 183.77 278.39" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M201.262 234.06C201.262 234.06 202.452 238.71 210.682 239.31C218.912 239.91 221.202 238.71 220.532 242.05C219.862 245.39 208.652 247.18 204.122 245.51" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M221.141 236.54C222.831 235.32 226.171 234.21 226.681 236.96C227.201 239.71 223.931 240.78 220.421 242.42M209.621 224.4L213.501 224.36L212.621 221.4M219.621 229.4L218.061 226.79L221.621 226.4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M84.6387 339.91C80.9887 323.16 77.9487 298.97 86.3187 284.84M89.5387 284.81C91.8187 284.81 93.6587 282.96 93.6587 280.69C93.6587 278.42 91.8187 276.57 89.5387 276.57C87.2587 276.57 85.4187 278.42 85.4187 280.69C85.4187 282.96 87.2587 284.81 89.5387 284.81Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M92.8502 316.55C86.1602 318.84 83.3102 323.21 83.1602 329.03C89.7902 327.07 93.5502 323.25 92.8502 316.55ZM79.8401 308.22C80.8301 301.22 78.2401 296.7 73.1201 293.92C71.8601 300.72 73.5501 305.81 79.8401 308.22ZM90.6302 276.08C92.3902 276.08 93.8102 274.66 93.8102 272.9C93.8102 271.14 92.3902 269.72 90.6302 269.72C88.8702 269.72 87.4501 271.14 87.4501 272.9C87.4501 274.66 88.8702 276.08 90.6302 276.08ZM84.0202 278.68C85.7802 278.68 87.2001 277.26 87.2001 275.5C87.2001 273.74 85.7802 272.32 84.0202 272.32C82.2602 272.32 80.8401 273.74 80.8401 275.5C80.8401 277.26 82.2602 278.68 84.0202 278.68ZM96.2901 279.95C98.0501 279.95 99.4702 278.53 99.4702 276.77C99.4702 275.01 98.0501 273.59 96.2901 273.59C94.5301 273.59 93.1102 275.01 93.1102 276.77C93.1102 278.53 94.5301 279.95 96.2901 279.95ZM100.64 359.99L82.3602 364.5L72.4102 342.92L99.4202 336.27L100.64 359.99Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M90.6299 276.08C92.3899 276.08 93.8098 274.66 93.8098 272.9C93.8098 271.14 92.3899 269.72 90.6299 269.72C88.8699 269.72 87.4498 271.14 87.4498 272.9C87.4498 274.66 88.8699 276.08 90.6299 276.08ZM84.0199 278.68C85.7799 278.68 87.1998 277.26 87.1998 275.5C87.1998 273.74 85.7799 272.32 84.0199 272.32C82.2599 272.32 80.8398 273.74 80.8398 275.5C80.8398 277.26 82.2599 278.68 84.0199 278.68ZM96.2898 279.95C98.0498 279.95 99.4698 278.53 99.4698 276.77C99.4698 275.01 98.0498 273.59 96.2898 273.59C94.5298 273.59 93.1098 275.01 93.1098 276.77C93.1098 278.53 94.5298 279.95 96.2898 279.95Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M362.182 98.12L355.592 94.9L355.272 87.57C355.252 87.03 354.512 86.89 354.282 87.38L351.282 94.02L344.032 94.34C343.492 94.36 343.352 95.1 343.842 95.33L350.412 98.3L350.512 105.51C350.512 106.05 351.252 106.22 351.492 105.73L354.672 99.2L361.962 99.1C362.502 99.1 362.672 98.36 362.182 98.12ZM160.672 9.35L155.492 6.82L155.242 1.06C155.222 0.630003 154.642 0.520002 154.472 0.910002L152.112 6.13L146.412 6.38C145.982 6.4 145.872 6.98 146.262 7.15L151.432 9.48L151.512 15.15C151.512 15.58 152.092 15.71 152.282 15.32L154.782 10.19L160.512 10.11C160.942 10.11 161.072 9.53 160.682 9.34L160.672 9.35ZM23.4116 153.56L19.4916 151.65L19.3016 147.29C19.2916 146.97 18.8516 146.88 18.7116 147.18L16.9316 151.13L12.6216 151.32C12.3016 151.33 12.2116 151.77 12.5116 151.91L16.4216 153.67L16.4816 157.96C16.4816 158.28 16.9216 158.38 17.0616 158.09L18.9516 154.21L23.2816 154.15C23.6016 154.15 23.7016 153.71 23.4116 153.57V153.56Z" fill="#CCFF00"/>
        <path d="M121.361 134.51L118.501 140.61M113.061 152.23L6.9511 378.74M4.23109 384.55L1.37109 390.66M273.511 152.91L274.811 159.53M277.471 172.98L318.661 381.44M180.261 153.29L165.371 210.17M237.981 204.39L245.261 266.97M248.821 316.76L256.091 379.34M145.881 274.11L130.991 330.99M124.851 351.79L115.231 388.55M319.991 388.16L321.301 394.78" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default NotFoundIcon
