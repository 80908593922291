import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  paperClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { DrawerExpandlessIcon } from "../../assets/icons/DrawerExpandlessIcon";
import { FilterIconSvg } from "../../assets/icons/FilterIconSvg";
import Chip from "../../shared/components/Chip";
import Chip3 from "../../shared/components/Chip3";
import TextInput from "../../shared/components/TextInput";
import { ButtonPrimary } from "../../shared/components/button/Buttons";
import { subscribeUserToNewsletter } from "../../services/user";
import { NewsletterCoverBagkground } from "../../assets/images/image";
import {
  toastError,
  toastNotification,
  toastSuccess,
} from "../../utils/ToastMessage";

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "1px solid rgba(255, 255, 255, 0.12)",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  //   padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type Props = {
  activeFilters: any;
  handleChangeActiveFilters: any;
  handleClearAllFilters: any;
};

const FilterOptionsSidebar = (props: Props) => {
  const [showFiltermenu, setShowFiltermenu] = useState(true);
  const [open, setOpen] = React.useState(true);
  const [expandedCategory, setExpandedCategory] = useState(true); // Initially expanded
  const [expandedAppliedFilter, setExpandedAppliedFilter] = useState(true); // Initially expanded
  const [appliedFilters, setAppliedFilters] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const tags = [
    "Hero Section",
    "Feature",
    "Content",
    "Image Grids",
    "Clients",
    "Banner",
    "Modals",
    "Contact Us",
    "Navigation",
    "Pricing Table",
    "Team",
    "Connect Wallet",
    "Upload",
    "Review",
    "Profile",
    "Testimonials",
    "Footer",
    "How It Works",
    "Page Not Found",
    "FAQ",
    "E-Commerce Category",
    "E-Commerce Product",
    "E-Commerce Checkout",
    "Newsletter Subscription",
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleAccordionToggleCategory = () => {
    setExpandedCategory(!expandedCategory); // Toggle the expanded state
  };

  const handleRemoveTagFromAppliedFilter = (tag: any) => {
    //Add tag to categories
    setCategories([...categories, tag]);

    //Remove from Applied Filters
    const temp = [];

    for (let category of appliedFilters) {
      if (category !== tag) {
        temp.push(category);
      }
    }
    setAppliedFilters(temp);
    props?.handleChangeActiveFilters("categories", tag);
  };

  const handleAddTagToAppliedFilter = (tag: any) => {
    //Add to applied filters
    setAppliedFilters([...appliedFilters, tag]);

    //Remove from Categories
    const temp = [];

    for (let category of categories) {
      if (category !== tag) {
        temp.push(category);
      }
    }
    setCategories(temp);
    props?.handleChangeActiveFilters("categories", tag);
  };

  const handleClearAllAppliedFilters = () => {
    setAppliedFilters([]);
    setCategories(tags);
    props?.handleClearAllFilters("categories");
  };

  const handleSubscribeUserToNewsletter = async () => {
    setLoading(true);
    try {
      await subscribeUserToNewsletter({ email: email })
        .then((res: any) => {
          if (res?.isSubscribed) {
            toastSuccess(
              "Successfully subscribed.",
              "User is successfully subscribed to newsletter."
            );
          } else {
            toastNotification(res?.response?.data?.message);
          }
        })
        .catch((err: any) => {
          if (
            err?.response?.data?.message === "This email is already Subscribed."
          ) {
            toastNotification(err?.response?.data?.message);
          } else {
            toastError(
              "Ops! Something went wrong.",
              err?.response?.data?.message
            );
          }
        });
    } catch (error: any) {
      toastError(
        "Ops! Something went wrong.",
        "Failed to subscribe. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, [categories, appliedFilters]);

  useEffect(() => {
    setCategories(tags);
  }, []);

  useEffect(() => {}, [props?.activeFilters]);

  return (
    <>
      {showFiltermenu && (
        <Drawer
          sx={{
            display: { xs: showFiltermenu ? "block" : "none", lg: "block" },
            [`& .${paperClasses.root}`]: {
              border: "none",
              position: "none",
              "&:hover": {},
            },
            "& .MuiDrawer-paper": {
              position: "initial !important",
              bgcolor: "transparent",
            },
          }}
          variant="permanent"
          open={open}
        >
          {/* Toggle expand button */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 1,
              width: "100%",
              borderRadius: "0px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ width: "100%", height: "100%", opacity: 0 }} />
          </IconButton>

          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: open ? "space-between" : "center",
              minHeight: "0px !important",
              borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
              alignContent: "center",
              padding: "26px 24px",
            }}
          >
            {/* Toggle collapse button */}
            {open && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
                // onClick={handleDrawerClose}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <FilterIconSvg />
                  <Typography
                    color={"white"}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    Filter
                  </Typography>
                </Box>
                {/* <DrawerExpandlessIcon color="white" /> */}
              </Box>
            )}
          </DrawerHeader>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
              height: "100%",
              padding: "16px 24px 24px 24px",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Accordion
                sx={{
                  width: "100%",
                  bgcolor: "transparent",
                  backgroundImage: "none",
                }}
                expanded={true}
              >
                <AccordionSummary
                  sx={{
                    width: "100%",
                    bgcolor: "transparent",
                    color: "white",
                    padding: "0px",
                  }}
                  expandIcon={
                    <Box>
                      {appliedFilters.length > 0 && (
                        <Typography
                          onClick={() => {
                            handleClearAllAppliedFilters();
                          }}
                          variant="body1"
                          sx={{
                            color: "white",
                            cursor: "pointer",
                            transform: "rotate(180deg)",
                          }}
                        >
                          Clear All
                        </Typography>
                      )}
                    </Box>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Applied Filters
                </AccordionSummary>

                <AccordionDetails
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    padding: "0px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                      padding: "0px",
                    }}
                  >
                    {appliedFilters.map((tag: any) => {
                      return (
                        <Chip3
                          key={tag}
                          sx={{ cursor: "pointer" }}
                          title={tag}
                          isActive={true}
                          onRemove={() => handleRemoveTagFromAppliedFilter(tag)}
                        />
                      );
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              style={{
                border: "1px solid rgba(255, 255, 255, 0.12)",
                height: "1px",
                width: "100%",
              }}
            />

            {/* --------Category filter container-------- */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Accordion
                sx={{
                  width: "100%",
                  bgcolor: "transparent",
                  backgroundImage: "none",
                }}
                expanded={expandedCategory}
                onChange={handleAccordionToggleCategory}
              >
                <AccordionSummary
                  sx={{
                    width: "100%",
                    bgcolor: "transparent",
                    color: "white",
                    padding: "0px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Categories
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    padding: "0px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                      padding: "0px",
                    }}
                  >
                    {categories.map((tag: any) => {
                      return (
                        <Chip
                          key={tag}
                          sx={{ cursor: "pointer" }}
                          title={tag}
                          isActive={false}
                          onClick={() => handleAddTagToAppliedFilter(tag)}
                        />
                      );
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box
              style={{
                border: "1px solid rgba(255, 255, 255, 0.12)",
                height: "1px",
                width: "100%",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                // gap: { xs: "24px", md: "32px" },
                gap: { xs: "24px", md: "24px" },
                borderRadius: "12px",
                bgcolor: "#1b1b1b",
                // padding: { xs: "10px 4px", sm: "10px", lg: "25px 20px" },
                padding: { xs: "10px 4px", sm: "10px", lg: "25px 12px" },
                maxWidth: "100%", // Set max width for the outer container
                overflow: "hidden",
                marginTop:"3px",
                width:"232px",
                // height:"258px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  // gap: { xs: "12px", md: "24px" },
                  gap: { xs: "8px", md: "8px" },
                  maxWidth: "100%", // Constrain inner box width
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "text.secondary",
                    // fontSize: "18px",
                    fontSize: "16px",
                    background:
                      "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    maxWidth: "100%", // Ensure the typography doesn't exceed the box's width
                    wordWrap: "break-word", // Wrap long words to avoid overflow
                    whiteSpace: "normal", // Allow text to wrap
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight:"22px",
                    width:"173px",
                    // height:"44px",
                    // margin:"22px 30px 8px 29px"
                  }}
                  variant="h1"
                >
                  Join our community and claim free products
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    fontSize: "12px",
                    maxWidth: "100%", // Set max width to avoid text overflow
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight:"16px",
                  }}
                >
                  No Spam. Only sweet content and updates of our products.<br/> Join
                  55,000+ other creators in our community.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  maxWidth: "100%", // Constrain the width of the button container
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <TextInput
                    inputStyles={{
                      // backgroundColor: "#1B1B1B",
                      backgroundColor: "#282828",
                      // fontSize: "18px",
                      fontSize: "12px",
                      // fontWeight: "400",
                      fontWeight: "500", 
                      width: "100%",
                      border:"1px solid #3D3D3D",
                      borderRadius:"8px",
                      lineHeight:"16px", 
                      color:"#FFFFFF",
                      padding:"11px 110px 11px 15px",
                    }}
                    value={email}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                    }}
                    placeholderFontSize="12px"
                    placeholder="Email address..."
                  />
                </Box>

                <ButtonPrimary
                  // LabelStyle={{ fontSize: "18px", fontWeight: "600" }}
                  LabelStyle={{ fontSize: "14px", fontWeight: "600", lineHeight:"16px" }}
                  sx={{ 
                    width: "100%",
                    borderRadius:"8px",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    padding:"15px",
                    border:"1px solid #526600", 
                  }} // Constrain button width
                  onClick={() => {
                    handleSubscribeUserToNewsletter();
                  }}
                  label="Submit"
                />
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default FilterOptionsSidebar;
