import React, { useState } from "react";
import { Box, Typography, Button, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PromoCodeCongratulationPopup from "../../../promoCode/PromoCodeCongratulationsPopup";

const PromoCodeInSettings = () => {
    const [activeCongratulationPopup, setActiveCongratulationPopup] =useState(false);
    const navigate = useNavigate();
  
    return (
      <Box  
        sx={{
          display: "flex",
          flexDirection: "column", 
          margin: { xs: "0px 10px 24px 10px"},
          maxWidth:"460px",
          width:"100%",
          gap:"10px",
          padding:"48px 56px",
          borderRadius:"24px",
          bgcolor:"rgba(255, 255, 255, 0.06)",
        }}
      >
  
          {activeCongratulationPopup && (
            <PromoCodeCongratulationPopup
              activeDialog={activeCongratulationPopup}
              setActiveDialog={setActiveCongratulationPopup}
            />
          )}
  
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection:"column",
            gap:"32px",
          }}
        >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection:"column",
                gap:"24px",
              }}
            >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection:"column",
                    gap:"16px",
                  }}
                >
                    <Typography
                        component="h1"
                        sx={{
                            width: "100%",
                            fontSize: "24px",
                            fontFamily: "'Inter Tight', sans-serif",
                            fontWeight: 600,
                            color: "#FFFFFF",
                        }}
                        >
                        Got a Promo Code? 🎁
                    </Typography>
                    <Typography
                        component="h4"
                        sx={{
                            width: "100%",
                            fontSize: "18px",
                            fontFamily: "'Inter Tight', sans-serif",
                            fontWeight: 400,
                            color: "rgba(255, 255, 255, 0.5)",
                        }}
                    >
                        Type it in and let the discounts rain!☔✨
                    </Typography>
                </Box>
                <InputBase
                    placeholder="Type your secret code..."
                    sx={{
                    width: "100%", 
                    height: "54px",
                    padding: "3px 16px",
                    borderRadius: "14px",
                    border: "1px solid #3D3D3D",
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    outline: "none",
                    fontFamily: "'Inter Tight', sans-serif",
                    "&::placeholder": {
                        color: "#8F8F8F",
                        fontWeight: 400,
                        lineHeight: "24px",
                    },
                    }}
                />  
            </Box>
            <Box
            sx={{
                width: "100%",
                maxWidth: "348px",
                display: "flex",
                gap: "16px",
                alignItems: "center",
            }}
            >
                <Button
                    sx={{
                    width: "100%",
                    borderRadius: "16px",
                    backgroundColor: "#CCFF00",
                    border: "1px solid #718B08",
                    color: "#000000",
                    padding: "16px 113px", 
                    fontSize: "18px",
                    fontFamily: "'Inter Tight', sans-serif",
                    fontWeight: 600,
                    lineHeight:"22px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#BBDD00",
                    },
                    }}
                >
                    Apply Magic
                </Button>
            </Box>
        </Box>
      </Box>
    );
}

export default PromoCodeInSettings
