import React, { useState } from "react";
import { Box, Typography, Button, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PromoCodeCongratulationPopup from "./PromoCodeCongratulationsPopup";

const PromoCodePage = () => {
  const [activeCongratulationPopup, setActiveCongratulationPopup] =useState(false);
  const navigate = useNavigate();

  return (
    <Box  
      sx={{
        minHeight: "81vh",
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column", 
        alignItems: "center",
        justifyContent: "center", 
        margin: { xs: "0px 10px 24px 10px"},
      }}
    >

        {activeCongratulationPopup && (
          <PromoCodeCongratulationPopup
            activeDialog={activeCongratulationPopup}
            setActiveDialog={setActiveCongratulationPopup}
          />
        )}

      <Box
        sx={{
          margin: { xs: "100px auto 24px 24px", sm: "191px 617.5px 24px 617.5px" },
          width: { xs: "87%", sm: "205px" },
          height: "48px",
          padding: "12px 30px",
          borderRadius: "79px",
          backgroundColor: "#1B1B1B",
          textAlign: "center",
          border: "1px solid #3D3D3D",
          boxSizing: "border-box",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "12px", sm: "15px" }, 
            fontFamily: "'Inter Tight', sans-serif",
            color: "#8D8D8D",
            fontWeight: 400,
          }}
        >
          Have a Promo Code?
        </Typography>
      </Box>
      <Typography
        component="h1"
        sx={{
          margin: { xs: "0 auto 16px 0", sm: "0 489px 16px" },
          width: "100%",
          maxWidth: "463px", 
          fontSize: { xs: "20px", sm: "32px" },
          fontFamily: "'Inter Tight', sans-serif",
          fontWeight: 600,
          background: "linear-gradient(to right, #FEFEFE, #757575)",
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          color: "transparent",
          textAlign: "center",
        }}
      >
        Unlock Premium features with a Special Discount Code!
      </Typography>
      <Typography
        component="h4"
        sx={{
          // margin: "0px auto 32px auto",
          margin: { xs: "0 auto 16px 0", sm: "0px auto 32px auto" },
          width: "100%",
          maxWidth: "347px",
          fontSize: { xs: "14px", sm: "18px" }, 
          fontFamily: "'Inter Tight', sans-serif",
          fontWeight: 400,
          color: "#888888",
          textAlign: "center",
        }}
      >
        Access 1000+ premium Figma components.
      </Typography>
      <Box
        sx={{
          // margin: "0 24px 191px 24px", 
          margin: { xs: "0 auto 16px 0", sm: "0 24px 191px 24px" },
          width: "100%",
          maxWidth: "481px",
          display: "flex",
          gap: "12px",
          flexDirection: { xs: "column", sm: "row" }, 
          alignItems: "center",
        }}
      >
        <InputBase
          placeholder="Enter your code here..."
          sx={{
            width: "100%", 
            maxWidth: "338px", 
            height: "54px",
            padding: "3px 16px",
            borderRadius: "14px",
            border: "1px solid #3D3D3D",
            backgroundColor: "#FFFFFF0F",
            color: "#FFFFFF",
            fontSize: "16px",
            outline: "none",
            fontFamily: "'Inter Tight', sans-serif",
            "&::placeholder": {
              color: "#8F8F8F",
              fontWeight: 400,
              lineHeight: "24px",
            },
          }}
        />  

        <Button
          sx={{
            width: "100%",
            maxWidth: "131px", 
            height: "54px",
            borderRadius: "14px",
            backgroundColor: "#CCFF00",
            border: "1px solid #718B08",
            color: "#000000",
            padding: "16px 41px",
            fontSize: "18px",
            fontFamily: "'Inter Tight', sans-serif",
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#BBDD00",
            },
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default PromoCodePage;
