import { AddRounded } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserIconSvg } from "../../../../assets/icons/UserIconSvg";
import TextInput from "../../../../shared/components/TextInput";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import { colors } from "../../../../utils/colors";
import {
  EyeLightIcon,
  EyeOffLightIcon,
  TrashIcon,
} from "../../../../assets/icons/icons";
import ProfileDialog from "./ProfileDialog";
import { useGlobalContext } from "../../../../App";
import { updateUserDetails } from "../../../../services/user";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import { ImageContainer } from "../../../../shared/components/ImageContainer";
import DeleteDialog from "./components/DeleteDialog";

type Props = {};

const Profile = (props: Props) => {
  const { userDetails } = useGlobalContext();

  const [showDeleteAccountUI, setShowDeleteAccountUI] =
    useState<boolean>(false);
  const [activeProfileDialog, setActiveProfileDialog] =
    useState<boolean>(false);
  const [activeDeleteProfileDialog, setActiveDeleteProfileDialog] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(true);

  const [profileImage, setProfileImage] = useState<any>("");
  const [avatar, setAvatar] = useState<any>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);

  const handleClickDeleteAccount = () => {
    setShowDeleteAccountUI(true);
  };

  const handleChangeProfile = (data?: any) => {
    if (data) {
      window.location.reload();
      setProfileImage(data?.profile);
      setAvatar(data?.avatar);
    }
    setActiveProfileDialog(false);
  };

  const handleUpdateUserDetails = async () => {
    setLoading(true);
    await updateUserDetails({
      userId: userDetails?.id,
      firstName: firstName,
      lastName: lastName,
    })
      .then((res: any) => {
        toastSuccess(
          "Successfully updated the details.",
          "User details are successfully updated."
        );
      })
      .catch((err: any) => {
        toastError(
          "Ops! Something went wrong.",
          "Failed updating the user details."
        );
      });
    setLoading(false);
  };

  const handleDeleteProfile = async () => {
    await updateUserDetails({
      userId: userDetails?.id,
      avatar: "",
    })
      .then((res: any) => {
        window.location.reload();
        toastSuccess(
          "Successfully deleted the user profile.",
          "User Pofile is successfully deleted."
        );
      })
      .catch((err: any) => {
        toastError(
          "Ops! Something went wrong.",
          "Failed deleting the user profile."
        );
      })
      .finally(() => {
        setActiveDeleteProfileDialog(false);
      });
  };

  useEffect(() => {}, [profileImage, avatar]);

  useEffect(() => {
    if (userDetails) {
      setProfileImage(userDetails?.avatar);
      setAvatar(userDetails?.secondaryAvatar);
      setFirstName(userDetails?.firstName);
      setLastName(userDetails?.lastName);
      setTimeout(() => {
        setLoadingDetails(false);
      }, 1000);
    }
  }, [userDetails]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        gap: "24px",
      }}
    >
      {/* -----Delete Profile confirmation dialog----- */}
      <Box sx={{ position: "absolute" }}>
        {activeDeleteProfileDialog && (
          <DeleteDialog
            deleteAction={() => {
              handleDeleteProfile();
            }}
            title="Are you sure you want to delete your profile ?"
            setIsDeleteDialogVisible={setActiveDeleteProfileDialog}
          />
        )}
      </Box>

      {!showDeleteAccountUI ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: { xs: "24px", sm: "48px 56px" },
              minWidth: { xs: "100%", sm: "460px" },
              maxWidth: { xs: "100%", sm: "460px" },
              borderRadius: "14px",
              bgcolor: "rgba(255, 255, 255, 0.06)",
              gap: "32px",
              alignItems: "center",
            }}
          >
            {loadingDetails ? (
              <Box
                sx={{
                  width: "100%",
                  minHeight: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  size={24}
                  sx={{
                    color: "white",
                    marginRight: "12px",
                  }}
                />
              </Box>
            ) : (
              <>
                {activeProfileDialog && (
                  <ProfileDialog
                    activeDialog={activeProfileDialog}
                    handleDialog={handleChangeProfile}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px", 
                    width:"100%",
                  }}
                >
                  <Typography
                    sx={{ color: "#FFFFFF", fontWeight: "600",fontSize:"18px",}} 
                    variant="subtitle1"
                  >
                    Profile
                  </Typography>
                  {/* <Typography
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      lineHeight: "24px",
                    }}
                    variant="body1"
                  >
                    Update your profile by clicking the image below 288x288 px
                    size recommended in PNG or JPG format only.
                  </Typography> */}
                </Box>

                {/* -----Profile image----- */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    bgcolor: "background.default",
                    position: "relative",
                    width: "144px",
                    height: "144px",
                    "&:hover .editIcon": {
                      opacity: 1,
                    },
                  }}
                >
                  {profileImage || avatar ? (
                    profileImage ? (
                      <img
                        style={{
                          width: "144px",
                          height: "144px",
                          borderRadius: "50%",
                        }}
                        alt="profile"
                        src={
                          profileImage instanceof File
                            ? URL.createObjectURL(profileImage)
                            : profileImage
                        }
                      />
                    ) : (
                      <img
                        style={{
                          width: "144px",
                          height: "144px",
                          borderRadius: "50%",
                        }}
                        alt="profile"
                        src={avatar}
                      />
                    )
                  ) : (
                    <UserIconSvg />
                  )}

                  <Box
                    className="editIcon"
                    onClick={() => {
                      profileImage
                        ? setActiveDeleteProfileDialog(true)
                        : setActiveProfileDialog(true);
                    }}
                    sx={{
                      boxShadow: profileImage
                        ? "none"
                        : "inset 0px 0px 0px 1px #3D3D3D",
                      bgcolor: profileImage ? "white" : "#292929",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      width: "40px",
                      height: "40px",
                      right: "7px",
                      bottom: 0,
                      borderRadius: "50%",
                      cursor: "pointer",
                      opacity: profileImage ? 0 : 1,
                    }}
                  >
                    {profileImage ? (
                      <ImageContainer
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "24px",
                          height: "24px",
                        }}
                        title="trash"
                      >
                        {TrashIcon}
                      </ImageContainer>
                    ) : (
                      <AddRounded
                        style={{
                          color: "white",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  {/* <Typography
                    sx={{ color: "white", fontWeight: "600" }}
                    variant="subtitle1"
                  >
                    Details
                  </Typography> */}

                  <Box>
                    <TextInput
                      inputStyles={{
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                      }}
                      lableStyles={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                      }}
                      value={firstName}
                      onChange={(e: any) => {
                        setFirstName(e.target.value);
                      }}
                      label="First Name"
                      placeholder="First Name"
                    />
                  </Box>

                  <Box>
                    <TextInput
                      inputStyles={{
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                      }}
                      lableStyles={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                      }}
                      label="Last Name"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e: any) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Box>

                  <Box>
                    <TextInput
                      inputStyles={{
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                      }}
                      lableStyles={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                      }}
                      label="Email"
                      placeholder="Email"
                      value={userDetails?.email}
                      disabled={true}
                    />
                  </Box>

                  <ButtonPrimary
                    isLoading={loading}
                    onClick={() => {
                      handleUpdateUserDetails();
                    }}
                    label="Update"
                  />
                </Box>
              </>
            )}
          </Box>

          <Typography
            onClick={() => {
              handleClickDeleteAccount();
            }}
            variant="subtitle1"
            sx={{
              color: "#E25454",
              textAlign: "center",
              width: "100%",
              cursor: "pointer",
              fontSize: "16px !important ",
              textDecoration: "underline", // Add underline
            }}
          >
            Delete my account
          </Typography>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: "24px", sm: "48px 56px" },
            minWidth: { xs: "100%", sm: "460px" },
            maxWidth: { xs: "100%", sm: "460px" },
            width: "100%",
            borderRadius: "14px",
            bgcolor: "rgba(255, 255, 255, 0.06)",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography
              sx={{ color: "#FFFFFF", fontWeight: "600" }}
              variant="subtitle1"
            >
              Delete account
            </Typography>
            <Typography sx={{ color: colors.primaryRed }} variant="body1">
              Are you sure you want to permanently delete your account?
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <Box>
              <TextInput
                inputStyles={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                }}
                value={confirmPassword}
                onChange={(e: any) => {
                  setConfirmPassword(e.target.value);
                }}
                lableStyles={{
                  fontSize: "16px !important",
                  fontWeight: "500",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
                label="To confirm deletion, enter your current password."
                placeholder="Enter your password"
                icononclick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
                icon={confirmPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
                type={!confirmPasswordVisible ? "password" : "text"}
              />
            </Box>

            <ButtonPrimary label="Delete" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Profile;
