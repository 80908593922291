import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import TextInput from "../shared/components/TextInput";
import { ImageContainer } from "../shared/components/ImageContainer";
import { WikiLogo } from "../assets/icons/icons";
import { LoginCoverImg } from "../assets/images/image";
import { requestResetPassword } from "../services/authentication";

type Props = {};

const ResetPassword = (props: Props) => {
  const theme = useTheme();

  const breakpoint_down_769 = useMediaQuery(theme.breakpoints.down(769));

  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  // Error handling states
  const [error, setError] = useState<string>();
  const [randomImageUrl, setRandomImageUrl] = useState(null);

  const handleResetPassword = async () => {
    try {
      await requestResetPassword({ username: email });
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Box
      sx={{
        minHeight: { xs: "100vh", lg: "auto" },
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {!breakpoint_down_769 && (
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "90%", md: "100%" },
              height: "100%",
              flex: 1,
            }}
          >
            {!isImagesLoading && (
              <img
                style={{
                  aspectRatio: "720/679",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="signin cover"
                src={randomImageUrl ? randomImageUrl["url"] : LoginCoverImg}
              />
            )}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            flex: 1.5,
            height: "100%",
            bgcolor: "background.default",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                mb: { xs: "36px", md: "81px" },
                position: "absolute",
                top: "60px",
              }}
            >
              <ImageContainer width={"54px"} height={"54px"} title="logo">
                {WikiLogo}
              </ImageContainer>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                alignItems: "flex-start",
                width: { xs: "100%", sm: "480px", lg: "528px" },
                justifyContent: "center",
                paddingX: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "12px",
                  textAlign: "center",
                  width: "100%",
                  mb: { xs: "24px", md: "32px" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "text.secondary",
                  }}
                  variant="h3"
                >
                  Reset your password
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "300",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                  variant="subtitle1"
                >
                  Enter your email address, and we'll send instructions to reset
                  your password.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      borderRadius: "12px",
                      maxWidth: "480px",
                    }}
                  >
                    <TextInput
                      lableStyles={{ fontSize: "12px", fontWeight: "600" }}
                      label="Email"
                      placeholder="Enter Your Email/ Employee ID"
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      required
                    />
                    <Typography
                      className="err_field"
                      id="loginIdNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "5px",
                    maxWidth: "480px",
                    marginTop: "16px",
                  }}
                >
                  <Button
                    id="signinButton"
                    sx={{
                      width: "100%",
                      height: "56px",
                      textAlign: "center",
                      textTransform: "none",
                      bgcolor: "primary.main",
                      color: "#FFFFFF",
                      borderRadius: "16px",
                      paddingY: "16px",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    onClick={() => handleResetPassword()}
                  >
                    <Typography
                      sx={{ color: "text.primary", fontWeight: "700" }}
                      variant="subtitle1"
                    >
                      {"Send Password Reset Email"}
                    </Typography>
                  </Button>
                  {error && (
                    <Typography
                      sx={{ width: "100%" }}
                      fontWeight={"400"}
                      variant="caption"
                      color="#E03137"
                    >
                      {error}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ResetPassword;
