import { Box,Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import TextInput from "../../shared/components/TextInput";
import { ButtonPrimary } from "../../shared/components/button/Buttons";
import { EyeLightIcon, EyeOffLightIcon } from "../../assets/icons/icons";
import {  resetPassword } from "../../services/authentication";
import { toastError, toastSuccess } from "../../utils/ToastMessage";
import { validate_if_not_empty } from "../../utils/extensions";
import { useTheme } from "@mui/material/styles";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { WikiLogo } from "../../assets/icons/icons";
import { LoginCoverImg } from "../../assets/images/image";
import { useSearchParams } from "react-router-dom";
import ErrorMessageIcon from "../../assets/icons/ErrorMessageIcon/ErrorMessageIcon";

type Props = {};

const ResetPasswordPage = (props: Props) => {
    
    // start I m taking this portion from ResetPassword.tsx 
    const theme = useTheme();
    const breakpoint_down_769 = useMediaQuery(theme.breakpoints.down(769));
    const [isImagesLoading, setIsImagesLoading] = useState(false);
    // End I m taking this portion from ResetPassword.tsx 
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id"); 
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
    const [confirmNewPasswordVisible, setconfirmNewPasswordVisible] = useState<boolean>(false);
  
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleSavePassword = async () => {
        let validationErrors: { [key: string]: string } = {};
        if (!newPassword) {
          validationErrors.newPassword = "New password is required.";
        } if (!confirmNewPassword) {
          validationErrors.confirmNewPassword = "Confirmation password is required.";
        } else if (newPassword !== confirmNewPassword) {
          validationErrors.confirmNewPassword = "Passwords do not match.";
        }

        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }

        setErrors({});
        setIsLoading(true);
          await resetPassword({newPassword: newPassword}, id || "")
            .then((res: any) => {
              if (res?.response?.data?.success || res?.success) {
                toastSuccess("Successfully reset password.", res?.message);
              } else {
                toastError(
                  "Ops! Something went wrong.",
                  res?.response?.data?.message
                );
              }
            })
            .catch((err: any) => {
              console.log(err);
              toastError(
                "Ops! Something went wrong.",
                "Failed to reset the password."
              );
            });

          setNewPassword("");
          setConfirmNewPassword(""); 
          setIsLoading(false);
    };

  return (

    <Box
    sx={{
      minHeight: { xs: "100vh", lg: "auto" },
      width: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {!breakpoint_down_769 && (
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "90%", md: "100%" },
            height: "100%",
            flex: 1,
          }}
        >
          {!isImagesLoading && (
            <img
              style={{
                aspectRatio: "720/679",
                width: "100%",
                height: "100%",
                objectFit: "cover",
            
              }}
              alt="signin cover"
              src={LoginCoverImg}
            />
          )}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: 1.5,
          height: "100%",
          bgcolor: "background.default",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              mb: { xs: "36px", md: "81px" },
              position: "absolute",
              top: "60px",
            }}
          >
            <ImageContainer width={"54px"} height={"54px"} title="logo">
              {WikiLogo}
            </ImageContainer>
          </Box>
          <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh", 
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: { xs: "24px", sm: "48px 56px" },
                    minWidth: { xs: "100%", sm: "460px" },
                    maxWidth: { xs: "100%", sm: "460px" },
                    borderRadius: "14px",
                    bgcolor: "rgba(255, 255, 255, 0.06)",
                    gap: "32px",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",     
                    }}
                >
                    <Typography
                        // variant="subtitle1"
                        sx={{ color: "#FFFFFF", fontWeight: "600",textAlign:"center" ,fontSize:"28px" }}
                        >
                        Reset Password
                    </Typography>
                </Box>

                <Box
                    sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    }}
                >

                    <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "5px",
                    }}
                    >
                        <TextInput
                            inputStyles={{
                            backgroundColor: "rgba(255, 255, 255, 0.06)",
                            boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                            fontSize: "16px",
                            border: errors.newPassword ? "1px solid #E25454" : "1px solid #ccc",
                            }}
                            lableStyles={{
                            fontSize: "16px !important",
                            fontWeight: "600",
                            }}
                            label="New Password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e: any) => {
                            setNewPassword(e.target.value);
                            }}
                            icononclick={() => setNewPasswordVisible(!newPasswordVisible)}
                            iconstyles={{ width: "24px", height: "24px" }}
                            icon={newPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
                            type={!newPasswordVisible ? "password" : "text"}
                        />
                        {errors.newPassword && (
                          <Typography
                          id="loginPassNotExist"
                          className="err_field"
                          color="#E25454"
                          variant="body2"
                          display="flex"
                          gap="4px"
                          marginTop={"12px"}
                          alignItems="center"
                          sx={{ width: "100%" }}
                          fontWeight={"400"}
                          fontSize="14px"
                          lineHeight={"16px"}
                          letterSpacing={"8%"}
                          >
                            <ErrorMessageIcon />
                            {errors.newPassword}
                          </Typography>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: "5px",
                        }}
                    >
                        <TextInput
                            inputStyles={{
                            backgroundColor: "rgba(255, 255, 255, 0.06)",
                            boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                            fontSize: "16px",
                            border: errors.confirmNewPassword
                            ? "1px solid #E25454"
                            : "1px solid #ccc",
                            }}
                            lableStyles={{
                            fontSize: "16px !important",
                            fontWeight: "600",
                            }}
                            label="Confirm New Password"
                            placeholder="Confirm New Password"
                            value={confirmNewPassword}
                            onChange={(e: any) => {
                            setConfirmNewPassword(e.target.value);
                            }}
                            icononclick={() => {
                                setconfirmNewPasswordVisible(!confirmNewPasswordVisible);
                            }}
                            iconstyles={{ width: "24px", height: "24px" }}
                            icon={confirmNewPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
                            type={!confirmNewPasswordVisible ? "password" : "text"}
                        />
                        {errors.confirmNewPassword && (
                          <Typography
                            id="loginPassNotExist"
                            className="err_field"
                            color="#E25454"
                            variant="body2"
                            display="flex"
                            gap="4px"
                            marginTop={"12px"}
                            alignItems="center"
                            sx={{ width: "100%" }}
                            fontWeight={"400"}
                            fontSize="14px"
                            lineHeight={"16px"}
                            letterSpacing={"8%"}
                          >
                            <ErrorMessageIcon />
                            {errors.confirmNewPassword}
                          </Typography>
                        )}
                    </Box>

                    <ButtonPrimary
                        onClick={() => {handleSavePassword()}}
                        label={isLoading ? "Submitting..." : "Submit"}
                        disabled={isLoading} 
                    />

                </Box>
            </Box>
          </Box>

        </Box>
      </Box>
    </Box>
  </Box> 

  );
};

export default ResetPasswordPage;
