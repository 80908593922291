import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { ArrowRightIcon } from "../../assets/icons/ArrowRightIcon";
import NewsletterSection from "../../main/NewsletterSection";
import { FigmaIcon } from "../../assets/icons/icons";
import { LockIconSvg } from "../../assets/icons/LockIconSvg";
import { AlertTriangleIconSvg } from "../../assets/icons/AlertTriangleIconSvg";
import { MessageQuestionIconSvg } from "../../assets/icons/MessageQuestionIconSvg";
import Chip from "../../shared/components/Chip";
import ComponentCard from "../home/components/ComponentCard";
import ReportDialog from "./ReportDialog";
import FeedbackDialog from "./FeedbackDialog";
import {
  getAllComponents,
  getComponentByBlurbUrl,
  getComponentSourceCodeByComponentId,
} from "../../services/component";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toastError, toastSuccess } from "../../utils/ToastMessage";
import { RotateElement } from "../../shared/components/Rotate";
import { useGlobalContext } from "../../App";
import { getUserById } from "../../services/user";
import { CopyIconSvg } from "../../assets/icons/CopyIconSvg";
import { monthStringFormatDate } from "../../utils/extensions";

type Props = {};

const ProjectComponentDetailScreen = (props: Props) => {
  const navigate = useNavigate();

  const { userDetails, setActiveLoginDialog } = useGlobalContext();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [components, setComponents] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  let [component, setComponent] = useState<any>({});

  const location = useLocation();
  const pathName = location.pathname; // Retrieve pathName from location
  const urlArray = pathName.split("/");
  const blurbUrl = urlArray[2];

  const carouselRef: any = useRef(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const handleCloseReportDialog = () => {
    setShowReportDialog(false);
  };

  const handleCloseFeedbackDialog = () => {
    setShowFeedbackDialog(false);
  };

  const getComponents = async () => {
    try {
      await getAllComponents()
        .then((res: any) => {
          setComponents(res?.paginatedComponents);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getComponentByBlurb = async () => {
    try {
      await getComponentByBlurbUrl(blurbUrl)
        .then((res: any) => {
          setComponent(res?.data);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyFigmaCode = async () => {
    if (!Cookies.get("jstoken")) {
      setActiveLoginDialog(true);
      return;
    }
    if (!isSubscribed) {
      navigate("/pricing");
      toastError("Please Subscribe to copy the Component.");
      return;
    }

    try {
      const componentSourceCode: any =
        await getComponentSourceCodeByComponentId(component?._id);
      const htmlContent = componentSourceCode?.data?.figmaCode;
      // Create a Blob with the HTML content and specify the MIME type as 'text/html'
      const blob = new Blob([htmlContent], { type: "text/html" });
      const clipboardItem = new ClipboardItem({ "text/html": blob });
      // Copy the Blob to the clipboard
      navigator.clipboard
        .write([clipboardItem])
        .then(() => {
          toastSuccess("Successfull", "Component is copied");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } catch (error) {
      toastError("Failed", "Something went wrong.");
    }
  };

  useEffect(() => {
    getComponents();
    getComponentByBlurb();
  }, [location]);

  const getUserSubscriptionsDetails = async (id: string) => {
    const response = await getUserById(id);
    const subscriptions = response?.data?.subscriptions;

    if (subscriptions?.length > 0) {
      setUserSubscriptions(subscriptions);
      for (let subscription of subscriptions) {
        if (subscription.isPaid === true && subscription.status === "ACTIVE") {
          setIsSubscribed(true);
        }
      }
    }
  };

  useEffect(() => {
    if (userDetails) {
      getUserSubscriptionsDetails(userDetails._id);
    }
  }, [userDetails]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {showReportDialog && (
        <ReportDialog
          activeDialog={showReportDialog}
          handleDialog={handleCloseReportDialog}
          data={component}
        />
      )}

      {showFeedbackDialog && (
        <FeedbackDialog
          activeDialog={showFeedbackDialog}
          handleDialog={handleCloseFeedbackDialog}
          data={component}
        />
      )}
      {/* Header Navigation */}
      {/* <Box
        sx={{
          display: "flex",
          padding: "22px 80px",
          alignItems: "center",
          gap: "11px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
        }}
      >
        <Typography
          onClick={() => {
            navigate("/");
          }}
          sx={{ color: "rgba(255, 255, 255, 0.5)", cursor: "pointer" }}
        >
          Home
        </Typography>
        <ArrowRightIcon
          width="18px"
          height="18px"
          color="rgba(255, 255, 255, 0.5)"
        />
        <Typography sx={{ color: "rgba(255, 255, 255, 0.5)" }}>
          All Components
        </Typography>
        <ArrowRightIcon
          width="18px"
          height="18px"
          color="rgba(255, 255, 255, 0.5)"
        />
        <Typography sx={{ color: "rgba(255, 255, 255, 0.5)" }}>
          {component?.title}
        </Typography>
      </Box> */}

      {/* Main Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: {
            xs: "40px 24px 40px 24px",
            sm: "40px",
            lg: "40px 80px 80px 80px",
          },
        }}
      >
        {/* ------Content detail section------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <Typography sx={{ color: "white", fontWeight: "600" }} variant="h4">
              {component?.title}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                fontWeight: "400",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {component?.blurb}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
              width: "100%",
              gap: "38px",
            }}
          >
            {/* ----Preview Image---- */}
            <Box
              sx={{
                flex: 1,
                borderRadius: "14px",
                bgcolor: "#1B1B1B",
                // padding: "38px",
                // aspectRatio: "4/3",  // I commented this line because aspectRatio: "4/3" lacks consistent cross-browser support.
                position: "relative",  // new added
                width: "100%",          // new added
                maxWidth: "100%",
                overflow: "hidden",    // new added
                 "&::before": {        // new added
                  content: '""',      // new added
                  display: "block",  // new added
                  paddingTop: "75%",  // new added
                }, 
              }}
            >
              <Box
                sx={{
                  position: "absolute", // new added
                  top: 0,  // new added
                  left: 0,  // new added
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  borderRadius: "14px",
                  // backgroundImage: component?.documents
                  //   ? `url(${component?.documents[0]?.url})`
                  //   : "",
                  // backgroundSize: "cover",
                  // backgroundRepeat: "no-repeat",
                  // backgroundPosition: "center",
                  // aspectRatio: "4/3",
                }}
              >
                <img
                  src={component?.documents ? component?.documents[0]?.url : ""}
                  alt="Preview"
                  style={{
                    width: "100%", 
                    height: "100%", 
                    // objectFit: "contain",
                    borderRadius: "14px", 
                  }}
                />
              </Box>
            </Box>

            {/* -----Details----- */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxWidth: "338px",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              {/* ---Figma--- */}
              <Box
                onClick={(e: any) => {
                  e.preventDefault();

                  handleCopyFigmaCode();
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#1B1B1B",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "14px",
                  justifyContent: "space-between",
                  padding: "15px",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <ImageContainer
                    style={{ width: "24px", height: "24px" }}
                    title="figma"
                  >
                    {FigmaIcon}
                  </ImageContainer>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      fontWeight: "400",
                    }}
                  >
                    Figma
                  </Typography>
                </Box>

                {Cookies.get("jstoken") && isSubscribed ? (
                  <CopyIconSvg color="white" />
                ) : (
                  <Box
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <LockIconSvg />
                  </Box>
                )}
              </Box>

              {/* ---Overview--- */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                  bgcolor: "#1B1B1B",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "14px",
                  justifyContent: "space-between",
                  padding: "24px",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "600",
                  }}
                  variant="h4"
                >
                  Overview
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                  variant="body1"
                >
                  {component?.description}
                </Typography>
              </Box>

              {/* ------Product Description------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                  bgcolor: "#1B1B1B",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "14px",
                  justifyContent: "space-between",
                  padding: "24px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "600",
                  }}
                  variant="h4"
                >
                  Product Description
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      Category
                    </Typography>

                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      Content
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      Release
                    </Typography>

                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      {component?.createdAt
                        ? monthStringFormatDate(component?.createdAt)
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      Author
                    </Typography>

                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      UI Wiki
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                onClick={() => {
                  if (Cookies.get("jstoken")) {
                    setShowFeedbackDialog(true);
                  } else {
                    navigate("/login");
                  }
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#1B1B1B",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "14px",
                  justifyContent: "flex-start",
                  padding: "15px",
                  gap: "12px",
                  cursor: "pointer",
                }}
              >
                <MessageQuestionIconSvg />
                <Typography
                  variant="subtitle1"
                  sx={{ color: "white", fontWeight: "600" }}
                >
                  Give Feedback
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  if (Cookies.get("jstoken") && userDetails?.id) {
                    setShowReportDialog(true);
                  } else {
                    navigate("/login");
                  }
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#1B1B1B",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "14px",
                  justifyContent: "flex-start",
                  padding: "15px",
                  gap: "12px",
                  cursor: "pointer",
                }}
              >
                <AlertTriangleIconSvg />
                <Typography
                  variant="subtitle1"
                  sx={{ color: "white", fontWeight: "600" }}
                >
                  Reports a problem
                </Typography>
              </Box>

              {/* ------Tags------ */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                {component?.tags?.map((tag: string, index: number) => {
                  return <Chip index={index} title={tag} />;
                })}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* --------Seperator-------- */}
        <Box
          sx={{
            flex: 1,
            height: "1px",
            border: "1px solid rgba(255, 255, 255, 0.12)",
            mt: "40px",
            mb: "40px",
          }}
        />

        {/* --------Related Cards Section-------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            mb: "80px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontWeight: "600" }} color="white" variant="h3">
              Related Components
            </Typography>
            <Typography
              onClick={() => {
                navigate("/search", { state: { tag: component?.tags } });
              }}
              sx={{
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.6)",
                cursor: "pointer",
              }}
              variant="subtitle1"
            >
              View more
            </Typography>
          </Box>

          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* Left Arrow */}
            <IconButton
              onClick={scrollLeft}
              sx={{
                position: "absolute",
                left: 0,
                zIndex: 10,
                cursor: "pointer",
                height: "100%",
                width: "60px",
                display: "flex",
                justifyContent: "center",
                borderRadius: "0px",
                alignItems: "center",
                background:
                  "linear-gradient(to right, rgba(0, 0, 0, 0.7), transparent)",
                border: "none",
              }}
            >
              <RotateElement
                sx={{ width: "32px", height: "32px" }}
                rotate="180deg"
              >
                <ArrowRightIcon width="32px" height="32px" color="white" />
              </RotateElement>
            </IconButton>

            {/* Carousel Box */}
            <Box
              className="HideScrollbar"
              ref={carouselRef}
              sx={{
                display: "flex",
                overflowX: "auto",
                scrollSnapType: "x mandatory",
                gap: "16px",
                padding: "10px 0",
              }}
            >
              {components?.map((data, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: "0 0 auto",
                    scrollSnapAlign: "start",
                    width: { xs: "270px", sm: "416px" },
                  }}
                >
                  <ComponentCard
                    data={data}
                    userSubscriptions={userSubscriptions}
                    isSubscribed={isSubscribed}
                  />
                </Box>
              ))}
            </Box>

            {/* Right Arrow */}
            <IconButton
              onClick={scrollRight}
              sx={{
                position: "absolute",
                right: 0,
                zIndex: 10,
                cursor: "pointer",
                height: "100%",
                width: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0px",
                background:
                  "linear-gradient(to left, rgba(0, 0, 0, 0.7), transparent)",
                ":hover": {
                  background:
                    "linear-gradient(to left, rgba(0, 0, 0, 0.7), transparent)",
                },
                border: "none",
              }}
            >
              <ArrowRightIcon width="32px" height="32px" color="white" />
            </IconButton>
          </Box>
        </Box>

        {/* ---------Newsletter section--------- */}
        <NewsletterSection />
      </Box>
    </Box>
  );
};

export default ProjectComponentDetailScreen;
